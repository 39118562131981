import type { GtmEventMultipleProducts } from '~/types/tracking/trackingMultipleProducts';
import type { Ecommerce } from '~/types/tracking/ecommerce';
import type { EventNameType } from '~/types/tracking/eventNameType';

/**
 * Composable to manage tracking on lists with multiple products
 */

export function useMultipleProductsTracking() {
    const gtm = useGtm();
    const { getLoginStatus, removeUndefinedProperties } = usePrepareForTracking();

    const trackMultipleProducts = (eventName: EventNameType, ecommerce: Ecommerce) => {
        gtm?.trackEvent({ ecommerce: null });

        const event: GtmEventMultipleProducts = {
            event: eventName,
            ...getLoginStatus(),
            ecommerce: removeUndefinedProperties(ecommerce),
        };

        gtm?.trackEvent(event);
    };

    return {
        trackMultipleProducts,
    };
}
